import type { BadgeColor } from '~/components/ui/Badge.vue';
import type { AggregationLevels, ComparisonAPIError, ComparisonAPIResponse } from '../types';

interface State {
  comparisonResponse: ComparisonAPIResponse | null;
  comparisonError: ComparisonAPIError | null;
  zoomPeriod: { start: Date; end: Date } | null;
  areasToCompare: { id: number; periodStart: Date; color: BadgeColor }[];
  aggregationLevel: AggregationLevels | null;
}

export const comparisonStore = defineStore('comparisonStore', {
  state: (): State => ({
    comparisonResponse: null,
    comparisonError: null,
    zoomPeriod: null,
    areasToCompare: [],
    aggregationLevel: null,
  }),
  getters: {
    getComparisonResponse(): State['comparisonResponse'] {
      return this.comparisonResponse;
    },
    getComparisonError(): State['comparisonError'] {
      return this.comparisonError;
    },
  },
  actions: {
    setComparisonResponse(comparisonResponse: State['comparisonResponse']): void {
      this.comparisonResponse = comparisonResponse;
    },
    setComparisonError(comparisonError: State['comparisonError']): void {
      this.comparisonError = comparisonError;
    },
    setZoomPeriod(zoomPeriod: State['zoomPeriod']): void {
      this.zoomPeriod = zoomPeriod;
    },
    updateAreasToCompare(areas: { id: number; periodStart: Date; color: BadgeColor }[]) {
      this.areasToCompare = areas;
    },
    removeAllAreaToCompare() {
      this.areasToCompare = [];
    },
    setAggregationLevel(aggregationLevel: State['aggregationLevel']): void {
      this.aggregationLevel = aggregationLevel;
    },
  },
});
