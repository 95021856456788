import type { AggregationLevels, ConsumptionAPIError, ConsumptionAPIResponse, ProductionAPIError, ProductionAPIResponse } from '../types';

interface State {
  consumptionResponse: ConsumptionAPIResponse | null;
  consumptionError: ConsumptionAPIError | null;
  productionResponse: ProductionAPIResponse | null;
  productionError: ProductionAPIError | null;
  loadingProduction: boolean;
  zoomPeriod: { start: Date; end: Date } | null;
  aggregationLevel: AggregationLevels | null;
}

export const chronologicalStore = defineStore('chronologicalStore', {
  state: (): State => ({
    zoomPeriod: null,
    loadingProduction: false,
    consumptionResponse: null,
    consumptionError: null,
    productionResponse: null,
    productionError: null,
    aggregationLevel: null,
  }),
  getters: {
    getConsumptionResponse(): State['consumptionResponse'] {
      return this.consumptionResponse;
    },
    getConsumptionError(): State['consumptionError'] {
      return this.consumptionError;
    },
  },
  actions: {
    setAggregateLevel(aggregationLevel: State['aggregationLevel']): void {
      this.aggregationLevel = aggregationLevel;
    },
    setConsumptionResponse(consumptionResponse: State['consumptionResponse']): void {
      this.consumptionResponse = consumptionResponse;
    },
    setConsumptionError(consumptionError: State['consumptionError']): void {
      this.consumptionError = consumptionError;
    },
    setProductionResponse(productionResponse: State['productionResponse']): void {
      this.productionResponse = productionResponse;
    },
    setProductionError(productionError: State['productionError']): void {
      this.productionError = productionError;
    },
    setLoadingProduction(loadingProduction: State['loadingProduction']): void {
      this.loadingProduction = loadingProduction;
    },
    setZoomPeriod(zoomPeriod: State['zoomPeriod']): void {
      this.zoomPeriod = zoomPeriod;
    },
  },
});
