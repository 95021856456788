import dayjs from 'dayjs';
import type { AggregationLevels } from '../../types';

export const useExploreFilter = () => {
  // Composable
  const { t } = useI18n();

  // Store
  const { getPeriod, areaId, chartType } = storeToRefs(exploreStore());
  const { zoomPeriod: chronologicalZoomPeriod } = storeToRefs(chronologicalStore());
  const { zoomPeriod: comparisonZoomPeriod } = storeToRefs(comparisonStore());

  // Methods
  const getAggregationLevelFromPeriod = (period: { start: Date; end: Date }): AggregationLevels[] => {
    if (!period) return ['day'];

    const possibleAggregationLevels = [];
    const start = dayjs(period.start);
    const end = dayjs(period.end);

    if (end.diff(start, 'minute', true) < 1000) possibleAggregationLevels.push('minute');
    if (end.diff(start, 'hour', true) < 240 && end.diff(start, 'hour', true) > 4) possibleAggregationLevels.push('hour');
    if (end.diff(start, 'day', true) < 1000 && end.diff(start, 'day', true) > 4) possibleAggregationLevels.push('day');
    if (end.diff(start, 'week', true) < 1000 && end.diff(start, 'week', true) > 3) possibleAggregationLevels.push('week');
    if (end.diff(start, 'month', true) < 1000 && end.diff(start, 'month', true) > 4) possibleAggregationLevels.push('month');

    return possibleAggregationLevels;
  };

  const getEPIOptionList = (): { key: string; label: string }[] => {
    const area = areaStore().getArea(areaId.value);

    const options = [
      {
        key: 'quantity',
        label: `kWh / ${area?.production_unit?.symbol ?? '--'}`,
      },
      {
        key: 'cost',
        label: `€ / ${area?.production_unit?.symbol ?? '--'}`,
      },
      {
        key: 'co2',
        label: `gCO2 / ${area?.production_unit?.symbol ?? '--'}`,
      },
    ];

    return options;
  };

  // Computed
  const getEnergyTypes = computed(() => {
    const area = areaStore().getArea(areaId.value);
    const meters = meterStore().getMeters.reduce((acc, meter) => {
      acc[meter.meter_type.energy_type] = acc[meter.meter_type.energy_type] || [];
      acc[meter.meter_type.energy_type].push(meter);
      return acc;
    }, {});

    return Object.keys(meters).map((energyType) => ({
      label: t(`global.energy_type.${energyType}`),
      key: energyType,
      secondaryLabel: area?.meters.some((meter) => meter.meter_type.energy_type === energyType) ? '' : meters[energyType][0].area.name,
      areaToRedirect: area?.meters.some((meter) => meter.meter_type.energy_type === energyType) ? null : meters[energyType][0].area.id,
    }));
  });

  const getAggregationLevelOptionList = computed((): { label: string; key: AggregationLevels; disabled: boolean }[] => {
    const period = () => {
      if (chartType.value === 'explore-chronological' && chronologicalZoomPeriod.value) return chronologicalZoomPeriod.value;
      if (chartType.value === 'explore-comparison' && comparisonZoomPeriod.value) return comparisonZoomPeriod.value;
      return getPeriod.value;
    };

    if (!period) return [];
    const possibleAggregationLevels = getAggregationLevelFromPeriod(period());

    return [
      {
        label: t('global.aggregate_levels.minute'),
        key: 'minute',
        disabled: !possibleAggregationLevels.includes('minute'),
      },
      {
        label: t('global.aggregate_levels.hour'),
        key: 'hour',
        disabled: !possibleAggregationLevels.includes('hour'),
      },
      {
        label: t('global.aggregate_levels.day'),
        key: 'day',
        disabled: !possibleAggregationLevels.includes('day'),
      },
      {
        label: t('global.aggregate_levels.week'),
        key: 'week',
        disabled: !possibleAggregationLevels.includes('week'),
      },
      {
        label: t('global.aggregate_levels.month'),
        key: 'month',
        disabled: !possibleAggregationLevels.includes('month'),
      },
    ];
  });

  const getZoneOptionList = computed((): { label: string; key: number }[] => {
    return [
      { label: t('explore.one_level'), key: 1 },
      { label: t('explore.two_levels'), key: 2 },
      { label: t('explore.three_levels'), key: 3 },
    ];
  });

  return {
    getAggregationLevelFromPeriod,
    getEPIOptionList,
    getAggregationLevelOptionList,
    getEnergyTypes,
    getZoneOptionList,
  };
};
